

.navbar-light .navbar-brand{
  /* color: #acd83a !important; */
  font-size:100%;
  font-weight: bold;
}
.nav-link
{
  /* color: #acd83a !important; */
  font-size:100%;
  /* font-weight: 300; */
  
}