/* BlogList styles */
.blog-list {
  padding: 2rem 0;
}

.page-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 0.5rem;
}

.page-subtitle {
  font-size: 1.125rem;
  color: #666;
  margin-bottom: 2rem;
}

.blog-card {
  border: none;
  background-color: #f8f9fa;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  margin-bottom: 1.5rem;
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.blog-card-link {
  text-decoration: none;
}

.image-container {
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  padding-top: 56.25%; /* Maintain 16:9 Aspect Ratio, adjust if necessary */
}

.blog-thumbnail {
  object-fit: cover; /* Ensure the image covers the container */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px; /* Optional: Apply rounding to the image */
}

.blog-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #212529;
}

.blog-content-preview {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1.5rem;
}

.read-more-btn {
  font-size: 1rem;
  color: #007bff;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.read-more-btn:hover {
  color: #0056b3;
}

/* BlogView styles */
.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  color: #444;
}

.not-found-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 1rem;
}

.not-found-message {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

.back-to-blogs {
  font-size: 1rem;
  color: #007bff;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.2s ease;
}

.back-to-blogs:hover {
  color: #0056b3;
}

/* Blog post header */
.blog-header {
  margin-bottom: 2rem;
  text-align: center;
}

.blog-title {
  font-size: 2.4rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 1rem;
}

.blog-card-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #212529;
}

.blog-image {
  max-width: 100%;
  height: auto;
  max-height: 400px;
  border-radius: 12px;
  margin-bottom: 1.5rem;
  transition: transform 0.3s ease;
}

.blog-image:hover {
  transform: scale(1.05);
}

.blog-content {
  font-size: 1.125rem;
  line-height: 1.75;
  color: #444;
  padding: 0 1rem;
  font-family: Arial, sans-serif; 

  
}

@media (min-width: 768px) {
  .page-title {
    font-size: 3rem;
  }

  .page-subtitle {
    font-size: 1.25rem;
  }

  .blog-title {
    font-size: 3rem;
  }

  .blog-card {
    display: flex;
    flex-direction: row;
    padding: 1rem;
  }

  .image-container {
    padding-top: 56.25%; /* Maintain aspect ratio */
    margin-right: 1.5rem;
  
  }

  .blog-content-preview {
    font-size: 1.125rem;
    color: #555;
  }

  .read-more-btn {
    font-size: 1.125rem;
  }

  /* Adjust for larger blog titles and content on tablets and larger screens */
  .blog-title {
    font-size: 2.4rem;
  }

  .blog-content {
    font-size: 1.25rem;
    padding: 0 2rem;
  }

  /* Blog list */
  .blog-card {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin-bottom: 1.5rem;
  }
}

/* Responsive adjustments for smaller devices */
@media (max-width: 576px) {
  .blog-card {
    padding: 0.75rem;
  }

  .blog-title {
    font-size: 1.25rem;
  }

  .blog-content {
    font-size: 1rem;
    padding: 0.5rem;
  }

  .image-container {
    padding-top: 75%; /* Make images square for mobile */
  }
}

/* Back link */
.back-link {
  font-size: 1rem;
  color: #007bff;
  text-decoration: none;
  margin-bottom: 2rem;
  display: inline-block;
  font-weight: 600;
  transition: color 0.2s ease;
}

.back-link:hover {
  color: #0056b3;
}

.blog-html-content {
  font-family: Roboto, Helvetica, Arial, sans-serif;; /* Force all child elements to inherit the parent font */

}

.main-blog * {
  font-family: Roboto, Helvetica, Arial, sans-serif;; /* Force all child elements to inherit the parent font */
}
