.contact-section {
    background-color: var(--white);
    width: 100%;
    margin-top: 0.5rem;
  }
  
  @media screen and (min-width: 799px) {
    .contact-section {
      --offset-height: 100px;
  
      display: grid;
      grid-template-columns: 3fr 1.5fr;
      width: 80vw;
      height: 475px;
      margin: 0 auto;
      top: var(--offset-height);
      left: 50%;
      margin-left: -40vw;
      margin-bottom: var(--offset-height);
      position: relative;      
      overflow: hidden;
      border-color: black;
      border-width: 6dvi;
      box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.15);
      border:groove
    }
  }