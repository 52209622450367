.carousel-indicators [data-bs-target]
{
	background-color: black !important;
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
    background-color: #8064A2;
}
@media only screen and (min-width: 1200px) {
.carousel .carousel-item {
    height: 700px;
  }
}
  
  .carousel-item img {
      position: absolute;
      object-fit: cover;
      top: 0;
      left: 0;    
      max-height: 700px;     
  }
  .img.d-block w-100 {
    height: 700px;
    width: 567px;
  }
  
  @media only screen and (max-width: 767px) {
    .carousel .carousel-item {
      height: 200px;
    }
    
  }
