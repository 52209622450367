section.content-prod{
     margin-left: 30px !important; 
    padding:40px;
   }

   .main-hero-para {
    font-size: 1rem;
    color: var(--text-color);
    margin: 1rem 0 1rem 0;
    font-weight: lighter;
    width: 100%;
    text-align: justify;
  }
  .alignright {
    text-align: left !important;
    display: flex !important;
    justify-content: flex-end !important;
   

  }

  .alignleft {
    text-align: left !important;
    display: flex !important;
    justify-content: flex-end !important;  
    
  }



  @media only screen and (min-width: 1200px) {
    .marginwhyuside {  
      margin-left: 10%;         
      margin-right: 10%;
    }
    .mainImageHeight {
      height:'700px';
      }
  }
    @media only screen and (max-width: 767px) {
    .marginwhyuside {  
      margin-left: 1%;              
    }
    .mainImageHeight {
      height:'200px';
      }
  }
  