
   .main-hero-para {
    font-size: 1rem;
    color: var(--text-color);
    margin: 1rem 0 2rem 0;
    font-weight: lighter;
    width: 90%;
    text-align: justify;
  }
  .alignright {
    text-align: left !important;
    display: flex !important;
    justify-content: flex-end !important;
   

  }

  .alignleft {
    text-align: left !important;
    display: flex !important;
    justify-content: flex-end !important;  
    
  }



  @media only screen and (max-width: 767px) {
    .marginTop {  
      margin-top: 2%;         
      padding: 2%;         
    }
  
    .margindesignerside {  
      margin-left: 10%;         
      margin-right: 10%;
    }
    .mainImageHeight {
      height:'200px';
      }
  
  }

  @media only screen and (min-width: 1200px) {
    .marginTop {  
      margin-top: 50px;         
      padding: 30px;
    }
  
    .margindesignerside {  
      margin-left: 10%;         
      margin-right: 10%;
    }

    .mainImageHeight {
      height:'700px';
      }
  
  }
    