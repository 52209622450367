section.content-prod{
     margin-left: 30px !important; 
    padding:40px;
   }

   .main-hero-para {
    font-size: 1rem;
    color: var(--text-color);
    margin: 1rem 0 2rem 0;
    font-weight: lighter;
    width: 90%;
    text-align: justify;
  }
  .alignright {
    text-align: left !important;
    display: flex !important;
    justify-content: flex-end !important;
   

  }

  .alignleft {
    text-align: left !important;
    display: flex !important;
    justify-content: flex-end !important;  
    
  }
  @media only screen and (min-width: 1200px) {
  .marginserviceside {  
    margin-left: 15%;         
    margin-right: 15%;
  }
  .mainImageHeight {
    height:'700px';
    }
}
  @media only screen and (max-width: 767px) {
  .marginserviceside {  
    margin-left: 1%;         
    margin-right: 1%;
  }
  .mainImageHeight {
    height:'200px';
    }
}




