
   .main-hero-para {
    font-size: 1rem;
    color: var(--text-color);
    margin: 1rem 0 2rem 0;
    font-weight: lighter;
    width: 90%;
    text-align: justify;
  }

  @media only screen and (min-width: 1200px) {
  .marginMyTeamTop {  
    margin-top: 20px;         
    padding: 10px;
  }

  section.content-prod{
    margin-left: 30px !important; 
   padding:40px;
  }

}
  @media only screen and (max-width: 767px) {
  .marginMyTeamTop {  
    margin-top: 1%;         
    padding: 1%;
  }

  section.content-prod{
    margin-left: 1% !important; 
   padding:5px;
  }

}
